import { GetOrdersStatusCode } from '@/enums/StatusCodes/StatusCode';
import { IItemSpec, IItemViewModel, IMerchantPortalOptionSettingViewModel, IMerchantPortalStyleSetting, INavBarOptionDto, IOperationResult, IOperationResultT, IPaginationResponse, IPortalQueryItemByMerchantRequest, IQueryOptionViewModel, IRegistRequest } from '@/Templates/interfaces/templatesInterfaces';
import {
    IAddItemToShoppingCartRequest,
    IAddItemToTempShoppingCartRequest,
    IBatchRemoveItemsFromShoppingCartRequest,
    IBatchRemoveItemsFromTempShoppingCartRequest,
    IGetNavBarOptionsRequest,
    IGetPaymentOrderRequest,
    ILoginRequest,
    IValidateStockAmountRequest
} from '../../interfaces/Requests';
import {
    INavBarSetting,
    IOperationResultGenericWithError,
    IOrder,
    IOrderHistoryDetailViewModel,
    IThirdPartyPaymentOrderViewModel,
    ITranslation,
    ValidateStockAmountResponse
} from '../../interfaces/Responses';

import useAPI from '../../Templates/lib/customHooks/useAPI';

// 獲取商戶是否過期API
export const useGetMerchantIsExpiredApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantIsExpired?merchantId=${merchantId}`, {
    signal
});

// 會員登錄API
export const useMemberLoginApi = (params: ILoginRequest, signal?: AbortSignal) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Member/MemberLogin`, {
    method: 'POST',
    body: params,
    signal
});

// 會員註冊API
export const useMemberRegistApi = (params: IRegistRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Member/MemberRegist`, {
    method: 'POST',
    body: params
});

// 獲取商戶門戶選項設置API
export const useGetMerchantPortalOptionSettingApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalOptionSettingViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantPortalOptionSetting?merchantId=${merchantId}`);

// 獲取商戶門戶樣式設置API
export const useGetMerchantPortalStyleSettingsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalStyleSetting[]>>(`${process.env.BASE_API_URL}/api/Portal/GetMerchantPortalStyleSettings?merchantId=${merchantId}`, {
    signal: signal,
});

// 獲取商戶商品API
export const useGetItemsByMerchantApi = (queryRequest: IPortalQueryItemByMerchantRequest, signal?: AbortSignal) => {
    const url = `${process.env.BASE_API_URL}/api/Portal/GetItemsByMerchant`;
    return useAPI<IOperationResultT<IPaginationResponse<IItemViewModel[]>>>(url, {
        method: 'GET',
        body: queryRequest,
        signal
    });
};

// 獲取商品規格API
export const useGetItemSpecsApi = (merchantId: BigInt, itemId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IItemSpec[]>>(`${process.env.BASE_API_URL}/api/Item/GetItemSpecs?merchantId=${merchantId}&itemId=${itemId.toString()}`, {
    method: 'GET',
    signal: signal
});

// 獲取導航欄設置API
export const useGetNavBarSettingApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<INavBarSetting>>(`${process.env.BASE_API_URL}/api/Portal/GetNavBarSetting?merchantId=${merchantId}`, {
    signal
});

// 獲取導航欄選項API
export const useGetNavBarOptionsApi = (params: IGetNavBarOptionsRequest, signal?: AbortSignal) => useAPI<IOperationResultT<INavBarOptionDto[]>>(`${process.env.BASE_API_URL}/api/Portal/GetNavBarOptions`, {
    method: 'POST',
    body: params,
    signal
});

// 獲取分類API
export const useGetCategoriesApi = (merchantId: BigInt) => useAPI(`${process.env.BASE_API_URL}/api/Category/GetMerchantCategories?merchantId=${merchantId}`);

// 添加到臨時購物車API
export const useAddToTempShoppingCartApi = (params: IAddItemToTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddItemToTempShoppingCart`, {
    method: 'POST',
    body: params
});

// 添加到購物車API
export const useAddToShoppingCartApi = (params: IAddItemToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddItemToShoppingCart`, {
    method: 'POST',
    body: params
});

// 直接添加訂單API
export const useAddOrderDirectlyApi = (params: IAddItemToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ShoppingCart/AddOrderDirectly`, {
    method: 'POST',
    body: params
});

// 獲取未註冊訂單API
export const useGetNoRegistOrderApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/Portal/GetNoRegistOrder?orderId=${orderId.toString()}`);

// 獲取會員訂單API
export const useGetMemberOrdersApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/Portal/GetMemberOrders`);

// 從購物車移除商品API
export const useBatchRemoveItemsFromShoppingCartApi = (params: IBatchRemoveItemsFromShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/BatchRemoveItemsFromShoppingCart`, {
    method: 'POST',
    body: params
});

// 從臨時購物車移除商品API
export const useBatchRemoveItemsFromTempShoppingCartApi = (params: IBatchRemoveItemsFromTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/BatchRemoveItemsFromTempShoppingCart`, {
    method: 'POST',
    body: params
});

// 驗證庫存數量API
export const useValidateStockAmountApi = (params: IValidateStockAmountRequest, signal?: AbortSignal) => useAPI<IOperationResultT<ValidateStockAmountResponse[]>>(`${process.env.BASE_API_URL}/api/Portal/ValidateStockAmount`, {
    method: 'POST',
    body: params,
    signal
});

// 獲取訂單歷史詳情視圖模型API
export const useGetOrderHistoryDetailViewModelApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IOrderHistoryDetailViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetOrderHistoryDetailViewModel?orderId=${orderId}`, {
    signal
});

// 獲取支持的語言API
export const useGetSupportedLanguagesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Portal/GetSupportedLanguages`, {
    signal
});

// 獲取翻譯API
export const useGetGetTranslationsApi = (language: string, signal?: AbortSignal) => useAPI<IOperationResultT<ITranslation[]>>(`${process.env.BASE_API_URL}/api/Portal/GetTranslations?language=${language}`, {
    signal
});

// 獲取最新第三方支付訂單API
export const useGetLastestThirdPartyPaymentOrderApi = (params: IGetPaymentOrderRequest, signal?: AbortSignal) => useAPI<IOperationResultGenericWithError<IThirdPartyPaymentOrderViewModel, GetOrdersStatusCode>>(`${process.env.BASE_API_URL}/api/Portal/GetLastestThirdPartyPaymentOrder`, {
    method: 'POST',
    body: params,
    signal
});

// 獲取門戶默認語言API
export const useGetPortalDefaultLanguageApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Portal/GetPortalDefaultLanguage?merchantId=${merchantId}`, {
    signal
});

// 獲取物流支持的支付設置ID API
export const useGetLogisticSupportPaymentSettingIdsApi = (logisticSettingId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<BigInt[]>>(`${process.env.BASE_API_URL}/api/Portal/GetLogisticSupportPaymentSettingIds?logisticSettingId=${logisticSettingId.toString()}`, {
    signal
});

// 獲取商戶查詢選項API
export const useGetQueryOptionsByMerchantIdApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IQueryOptionViewModel>>(`${process.env.BASE_API_URL}/api/Portal/GetQueryOptionsByMerchantId?merchantId=${merchantId}`, {
    signal
});