import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { TemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useMemo } from "react";

const PrivacyPolicyPage: React.FC = () => {
    const {
        merchantId,
        messageApi,
        merchantPortalOptionSetting,
        merchantPortalStyleSettings,
        deviceType,
        isPageLoading
    } = useContext(GlobalContext);

    const { translate } = useContext(TranslationContext);

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`@/Templates/Shop/Pages/PrivacyPolicyPage/Template_0`).then(mod => mod.default),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, []);

    if (isPageLoading) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            deviceType={deviceType}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            messageApi={messageApi}
            isPreviewMode={false}
            config={merchantPortalStyleSettings?.find(
                x => x.type === MerchantPortalStyleSettingType.Pages &&
                    x.name === MerchantPortalStyleShopPages.PrivacyPolicyPage
            )?.styleSetting}
            translate={translate}
            portalOptionSettingViewModel={merchantPortalOptionSetting}
            portalStyleSettings={merchantPortalStyleSettings}
        />
    );
};

export default PrivacyPolicyPage;