import { MD5 } from 'crypto-js';

export class SignHelper {
    async generateDynamicTimeSignAsync(signText: string, dateTimeOffset: Date): Promise<string> {
        const dynamicTypeString = dateTimeOffset.toISOString();
        return this.encryptByMD5(`${signText}${dynamicTypeString}`);
    }

    encryptByMD5(input: string): string {
        return MD5(input).toString();
    }
}