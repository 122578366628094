import { BASE_PATH } from '@/constants/routes';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CountdownTimer = () => {
    const [count, setCount] = useState(5);
    const navigate = useNavigate();
    const { translate } = useContext(TranslationContext);

    useEffect(() => {
        const timer = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (count === 0) {
            navigate(`${BASE_PATH}/`);
        }
    }, [count, history]);

    return <label>{translate('Redirect to home')}{'.'.repeat(count)}</label>;
};

export default CountdownTimer;