import { CheckOutStep, FrontEndDisplayType } from "@/Templates/enums/templateEnums";
import { IMemberLogisticOption, IPortalMerchantThirdPartyLogisticSettingViewModel, IPortalMerchantThirdPartyPaymentSettingViewModel, IShoppingCartItem, IShoppingCartItemViewModel, IShoppingCartViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useAddMemberLogisticOptionApi, useCreatePaymentOrder, useDeleteMemberLogisticOptionApi, useFillMemberLogisticOptionWithThirdPartyLogisticApi, useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi, useGetAnonymousLogisticOptionsApi, useGetLogisticsMainTypesApi, useGetMemberLogisticOptionsApi, useGetMerchantThirdPartyLogisticSettingViewModes, useGetPaymentMainTypesApi, useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi, useGetShoppingCartApi, useGetTempShoppingCartApi } from '@/Templates/lib/apis';
import { UrlHelper } from '@/Templates/lib/UrlHelper';
import { MessageInstance } from 'antd/es/message/interface';
import JSONBig from 'json-bigint';
import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { useMutation, useQuery } from 'react-query';

export interface ICheckOutContext {
    selectedItems: IShoppingCartItem[];
    setSelectedItems: React.Dispatch<React.SetStateAction<IShoppingCartItem[]>>;
    selectedLogistic: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined;
    setSelectedLogistic: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>>;
    selectedLogisticOption: IMemberLogisticOption | undefined;
    setSelectedLogisticOption: React.Dispatch<React.SetStateAction<IMemberLogisticOption | undefined>>;
    isOnlinePayment: boolean;
    setIsOnlinePayment: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    setSelectedPayment: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>>;
    currentStep: CheckOutStep;
    setCurrentStep: React.Dispatch<React.SetStateAction<CheckOutStep>>;
    userWantReservePay: boolean;
    setUserWantReservePay: React.Dispatch<React.SetStateAction<boolean>>;
    logisticValues: { [key: string]: string };
    setLogisticValues: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    paymentValues: { [key: string]: string } | undefined;
    setPaymentValues: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>;
    logiscticSupportedPaymentSettingIds: BigInt[];
    setLogiscticSupportedPaymentSettingIds: React.Dispatch<React.SetStateAction<BigInt[]>>;
    receiverInfos: any;
    setReceiverInfos: React.Dispatch<React.SetStateAction<any>>;
    invoiceCountry: string;
    setInvoiceCountry: React.Dispatch<React.SetStateAction<string>>;
    invoiceValues: Record<string, string>;
    setInvoiceValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    invoiceValidate: boolean;
    setInvoiceValidate: React.Dispatch<React.SetStateAction<boolean>>;
    shoppingCartData: IShoppingCartViewModel | undefined;
    refetchShoppingCart: () => void;
    isLoadingShoppingCart: boolean;
    paymentMainTypes: string[] | undefined;
    portalMerchantPaymentSettings: IPortalMerchantThirdPartyPaymentSettingViewModel[] | undefined;
    logisticsMainTypes: string[] | undefined;
    logisticMethods: IPortalMerchantThirdPartyLogisticSettingViewModel[] | undefined;
    memberLogisticOptions: IMemberLogisticOption[] | undefined;
    handleAddMemberLogisticOption: (values: Record<string, string>) => Promise<void>;
    handleDeleteMemberLogisticOption: (memberLogisticOptionId: BigInt) => Promise<void>;
    sendPaymentRequest: (orderId: BigInt) => Promise<void>;
    handleAddStoreAddressOption: (logisticVM: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined) => Promise<void>;
    refetchPaymentMainTypes: () => void;
    refetchPaymentSettings: () => void;
    refetchLogisticsMainTypes: () => void;
    refetchLogisticMethods: () => void;
    mutateMemberLogisticOptions: (isLogin: boolean) => void;
    isLoadingPaymentMainTypes: boolean;
    isLoadingPaymentSettings: boolean;
    nextStep: () => void;
    prevStep: () => void;
    handleSelectAll: (items: IShoppingCartItemViewModel[], isSelected: boolean) => void;
    handleSelectItem: (item: IShoppingCartItemViewModel, checked: boolean) => void;
    memberSelectedParameters: Record<string, string>;
    setMemberSelectedParameters: (key: string, value: string) => void;
    selectedMemberLogisticOption: IMemberLogisticOption | undefined;
    setSelectedMemberLogisticOption: React.Dispatch<React.SetStateAction<IMemberLogisticOption | undefined>>;
    isAddingStoreAddress: boolean;
    isDeletingLogisticOption: boolean;
}

export const CheckOutContext = createContext<ICheckOutContext>(null!);

export const CheckOutProvider = ({
    translate,
    children,
    merchantId,
    isLogin,
    tryGetSessionId,
    messageApi
}: {
    translate: (key: string) => string;
    children: ReactNode,
    merchantId: BigInt,
    isLogin: boolean,
    tryGetSessionId: () => BigInt,
    messageApi: MessageInstance
}) => {
    const urlHelper = new UrlHelper();
    const [selectedItems, setSelectedItems] = useState<IShoppingCartItem[]>([]);
    const [selectedLogistic, setSelectedLogistic] = useState<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const [selectedLogisticOption, setSelectedLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [isOnlinePayment, setIsOnlinePayment] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<CheckOutStep>(CheckOutStep.SelectItem);
    const [userWantReservePay, setUserWantReservePay] = useState<boolean>(false);
    const [logisticValues, setLogisticValues] = useState<{ [key: string]: string }>({});
    const [paymentValues, setPaymentValues] = useState<{ [key: string]: string } | undefined>(undefined);
    const [logiscticSupportedPaymentSettingIds, setLogiscticSupportedPaymentSettingIds] = useState<BigInt[]>([]);
    const [receiverInfos, setReceiverInfos] = useState<any>({});
    const [invoiceCountry, setInvoiceCountry] = useState<string>('Unset');
    const [invoiceValues, setInvoiceValues] = useState<Record<string, string>>({});
    const [invoiceValidate, setInvoiceValidate] = useState<boolean>(false);
    const [memberSelectedParameters, setMemberSelectedParameters] = useState<Record<string, string>>({});
    const [selectedMemberLogisticOption, setSelectedMemberLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [isAddingStoreAddress, setIsAddingStoreAddress] = useState(false);
    const [isDeletingLogisticOption, setIsDeletingLogisticOption] = useState(false);
    // 新增 API 相關的查詢和變異
    const { data: shoppingCartData, refetch: refetchShoppingCart, isLoading: isLoadingShoppingCart } = useQuery(
        ["GetShoppingCartApi", merchantId, isLogin],
        async () => isLogin ? await useGetShoppingCartApi(tryGetSessionId()) : await useGetTempShoppingCartApi(merchantId, tryGetSessionId()),
        { enabled: true, refetchOnWindowFocus: false }
    );

    const { data: paymentMainTypesData, refetch: refetchPaymentMainTypes, isLoading: isLoadingPaymentMainTypes } = useQuery(
        'getPaymentMainTypes',
        () => useGetPaymentMainTypesApi(),
    );

    const { data: portalMerchantPaymentSettingsData, refetch: refetchPaymentSettings, isLoading: isLoadingPaymentSettings } = useQuery(
        ['getMerchantThirdPartyPaymentSettingViewModels', merchantId],
        () => useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi(merchantId)
    );

    const { data: logisticsMainTypesData, refetch: refetchLogisticsMainTypes } = useQuery(
        'getLogisticsMainTypesApi',
        () => useGetLogisticsMainTypesApi()
    );

    const { data: logisticMethodsData, refetch: refetchLogisticMethods } = useQuery(
        ['getMerchantThirdPartyLogisticSettingViewModes', merchantId],
        () => useGetMerchantThirdPartyLogisticSettingViewModes(merchantId)
    );

    const { data: memberLogisticOptionsData, mutate: mutateMemberLogisticOptions } = useMutation(
        async (isLogin: boolean) => isLogin ? await useGetMemberLogisticOptionsApi(merchantId) : await useGetAnonymousLogisticOptionsApi(tryGetSessionId()),
    );

    const handleAddMemberLogisticOption = useCallback(async (values: Record<string, string>) => {
        if (!selectedLogistic) return;
        const response = await useAddMemberLogisticOptionApi({
            thirdPartyLogisticId: selectedLogistic.thirdPartySettingId,
            values
        });
        if (response.isSuccess) {
            messageApi.success(translate('Address added successfully'));
            mutateMemberLogisticOptions(isLogin);
        } else {
            messageApi.error(translate(response.message || 'Failed to add address'));
        }
    }, [selectedLogistic, messageApi, translate, mutateMemberLogisticOptions, isLogin]);

    const handleDeleteMemberLogisticOption = useCallback(async (memberLogisticOptionId: BigInt) => {
        try {
            setIsDeletingLogisticOption(true);
            const response = await useDeleteMemberLogisticOptionApi({
                sessionId: tryGetSessionId(),
                memberLogisticOptionId
            });
            if (response.isSuccess) {
                messageApi.success(translate('Address deleted successfully'));
                mutateMemberLogisticOptions(isLogin);
            } else {
                messageApi.error(translate(response.message || 'Failed to delete address'));
            }
        } finally {
            setIsDeletingLogisticOption(false);
        }
    }, [tryGetSessionId, messageApi, translate, mutateMemberLogisticOptions, isLogin]);

    const sendPaymentRequest = useCallback(async (orderId: BigInt) => {
        if (!selectedPayment) {
            messageApi.error(translate("Please select a payment method"));
            return;
        }

        const frontEndValues = { ...paymentValues, ['FrontEndHostDomain']: `${window.location.protocol}//${window.location.host}` };
        const response = await useCreatePaymentOrder({
            orderId: orderId,
            settingId: selectedPayment.thirdPartySettingId,
            frontEndValues: frontEndValues
        });

        if (!response.isSuccess) {
            messageApi.error(translate("Failed to send payment request"));
            return;
        }

        messageApi.success(translate("Send payment request success"));
        // 處理支付響應...
    }, [selectedPayment, paymentValues, messageApi, translate]);

    const handleAddStoreAddressOption = useCallback(async (logisticVM: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined) => {
        try {
            setIsAddingStoreAddress(true);
            if (!logisticVM) return;

            const frontEndValues: { [key: string]: string } = {
                ...logisticValues,
                'MerchantID': merchantId.toString(),
                'IsCollection': logisticVM.isSupportReservePay ? userWantReservePay.toString() : 'false',
                'FrontEndHostDomain': `${window.location.protocol}//${window.location.host}`
            };

            const apiFunction = isLogin
                ? useFillMemberLogisticOptionWithThirdPartyLogisticApi
                : useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi;

            const requestParams = {
                merchantId,
                thirdPartyLogisticId: selectedLogistic?.thirdPartySettingId!,
                frontEndValues,
                sessionId: tryGetSessionId()
            };

            const response = await apiFunction(requestParams);
            if (response.isSuccess && response.result) {
                const data = JSONBig.parse(response.result?.data);
                switch (response.result?.type) {
                    case FrontEndDisplayType.PageFormPost:
                        urlHelper.pageFormPost(response.result?.url, data);
                        break;
                    case FrontEndDisplayType.PageRedirectURL:
                        urlHelper.openRedirect(response.result?.url, data);
                        break;
                    case FrontEndDisplayType.OpenNewPage:
                        urlHelper.openInPopup(response.result?.url, data);
                        break;
                    default:
                        break;
                }
            } else {
                messageApi.error(translate(response.message || 'Failed to add store address'));
            }
        } finally {
            setIsAddingStoreAddress(false);
        }
    }, [isLogin, merchantId, selectedLogistic, userWantReservePay, tryGetSessionId, logisticValues, messageApi, translate]);

    const nextStep = useCallback(() => {
        setCurrentStep(prevStep => (prevStep === CheckOutStep.Logistic && userWantReservePay) ? CheckOutStep.Confirm : prevStep + 1);
    }, [userWantReservePay]);

    const prevStep = useCallback(() => {
        setCurrentStep(prevStep => (prevStep === CheckOutStep.Confirm && userWantReservePay) ? CheckOutStep.Logistic : prevStep - 1);
    }, [userWantReservePay]);

    const handleSelectAll = useCallback((items: IShoppingCartItemViewModel[], isSelected: boolean) => {
        setSelectedItems(isSelected ? items : []);
    }, []);

    const handleSelectItem = useCallback((item: IShoppingCartItemViewModel, checked: boolean) => {
        if (checked) {
            if (selectedItems.length > 0) {
                if (selectedItems[0].currency !== item.currency) {
                    messageApi.error(`${translate('You can only select items with the same currency')}.`);
                    return;
                }
                if (selectedItems[0].logisticOrderLimitType !== item.logisticOrderLimitType) {
                    messageApi.error(`${translate('You can only select items with the same logistic order limit type')}.`);
                    return;
                }
            }
            setSelectedItems(prevItems => [...prevItems, item]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(i => !((i.itemId === item.itemId) && (i.itemSpecId === item.itemSpecId))));
        }
    }, [selectedItems, messageApi, translate]);

    return (
        <CheckOutContext.Provider
            value={{
                selectedItems,
                setSelectedItems,
                selectedLogistic,
                setSelectedLogistic,
                selectedLogisticOption,
                setSelectedLogisticOption,
                isOnlinePayment,
                setIsOnlinePayment,
                selectedPayment,
                setSelectedPayment,
                currentStep,
                setCurrentStep,
                userWantReservePay,
                setUserWantReservePay,
                logisticValues,
                setLogisticValues,
                paymentValues,
                setPaymentValues,
                logiscticSupportedPaymentSettingIds,
                setLogiscticSupportedPaymentSettingIds,
                receiverInfos,
                setReceiverInfos,
                invoiceCountry,
                setInvoiceCountry,
                invoiceValues,
                setInvoiceValues,
                invoiceValidate,
                setInvoiceValidate,
                shoppingCartData: shoppingCartData?.isSuccess ? shoppingCartData.result as IShoppingCartViewModel : undefined,
                refetchShoppingCart,
                isLoadingShoppingCart,
                handleAddMemberLogisticOption,
                handleDeleteMemberLogisticOption,
                sendPaymentRequest,
                handleAddStoreAddressOption,
                refetchPaymentMainTypes,
                refetchPaymentSettings,
                refetchLogisticsMainTypes,
                refetchLogisticMethods,
                mutateMemberLogisticOptions,
                isLoadingPaymentMainTypes,
                isLoadingPaymentSettings,
                paymentMainTypes: paymentMainTypesData?.isSuccess ? paymentMainTypesData.result : [],
                portalMerchantPaymentSettings: portalMerchantPaymentSettingsData?.isSuccess ? portalMerchantPaymentSettingsData.result : [],
                logisticsMainTypes: logisticsMainTypesData?.isSuccess ? logisticsMainTypesData.result : [],
                logisticMethods: logisticMethodsData?.isSuccess ? logisticMethodsData.result : [],
                memberLogisticOptions: memberLogisticOptionsData?.isSuccess ? memberLogisticOptionsData.result : [],
                nextStep,
                prevStep,
                handleSelectAll,
                handleSelectItem,
                memberSelectedParameters,
                setMemberSelectedParameters: (key, value) => {
                    setMemberSelectedParameters(prev => ({ ...prev, [key]: value }));
                },
                selectedMemberLogisticOption,
                setSelectedMemberLogisticOption,
                isAddingStoreAddress,
                isDeletingLogisticOption
            }}
        >
            {children}
        </CheckOutContext.Provider>
    );
};

export const useCheckOut = () => useContext(CheckOutContext);