import { OrderStatus } from "@/enums/Enums";
import { GetOrdersStatusCode } from "@/enums/StatusCodes/StatusCode";
import { IOrder } from "@/interfaces/Responses";
import { IOrderDetailViewModel } from "@/interfaces/VIewModels";
import { useGetLastestThirdPartyPaymentOrderApi, useGetLogisticSupportPaymentSettingIdsApi, useGetMemberOrdersApi, useGetNoRegistOrderApi, useGetOrderHistoryDetailViewModelApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from "@/Templates/enums/templateEnums";
import PaymentSelector, { IPaymentSelectorRef } from "@/Templates/Shop/Pages/ShoppingCartPage/Components/PaymentSelector";
import { CheckOutContext } from "@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";
import { Button, Card, Col, Flex, Modal, Row, Table, TablePaginationConfig } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useQuery } from "react-query";

type TablePaginationPosition = NonNullable<TablePaginationConfig['position']>[number];

export interface IOrdersProps {
    orderId: string | undefined;
}
export interface IOrdersRef {
    onRefresh: () => void;
}

const Orders = React.forwardRef((props: IOrdersProps, ref: React.ForwardedRef<IOrdersRef | undefined>) => {
    const checkOutContext = useContext(CheckOutContext);
    const { deviceType, merchantId, messageApi, tryGetSessionId, isLogin } = useContext(GlobalContext);
    const { data: orders, refetch, isLoading } = useQuery("GetMemberOrders", isLogin ? () => useGetMemberOrdersApi() : () => useGetNoRegistOrderApi(BigInt(props.orderId || '0')), { enabled: props.orderId !== undefined || isLogin });
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>(undefined);
    const [orderDetailAggregateViewModel, setOrderDetailAggregateViewModel] = useState<IOrderDetailViewModel | undefined>(undefined);
    const [memberOrdersColumns, setMemberOrdersColumns] = useState<any[]>([]);
    const [orderDetailItemColumns, setOrderDetailItemColumns] = useState<any[]>([]);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const [scrollX, setScrollX] = useState(568);
    const paymentSelectorRef = useRef<IPaymentSelectorRef>();

    const handleOperation = async (id: BigInt) => {
        const order = orders?.result?.data.find((order) => order.id.toString() === id.toString());
        if (!order) {
            messageApi.error("Failed to get order detail");
            return;
        }

        const orderHistoryDetailVM = await useGetOrderHistoryDetailViewModelApi(order.id);
        if (!orderHistoryDetailVM.isSuccess) {
            messageApi.error("Failed to get order vm detail");
            return;
        }

        const getSupportPaymentSettingIdsAction = await useGetLogisticSupportPaymentSettingIdsApi(orderHistoryDetailVM.result?.memberLogisticOption?.thirdPartyLogisticId || BigInt(0));
        const paymentDetail = await useGetLastestThirdPartyPaymentOrderApi({
            orderId: order.id,
            merchantId: merchantId,
            sessionId: tryGetSessionId(),
        });

        if (!paymentDetail.isSuccess && paymentDetail.errorModel.status !== GetOrdersStatusCode.OrderNotFound) {
            messageApi.error("Failed to get payment detail");
        }

        setSelectedOrder(order);
        setOrderDetailAggregateViewModel({
            order: order,
            orderHistoryDetailViewModel: orderHistoryDetailVM.result,
            orderPaymentDetailViewModel: paymentDetail.result,
        });
        if (getSupportPaymentSettingIdsAction.isSuccess && getSupportPaymentSettingIdsAction.result) {
            checkOutContext.setLogiscticSupportedPaymentSettingIds(getSupportPaymentSettingIdsAction.result);
        }
        setIsModalVisible(true);
    };

    const setColumns = (device: DeviceType) => {
        switch (device) {
            case DeviceType.Desktop:
            case DeviceType.LapTop:
                setPCColumns();
                break;
            case DeviceType.Mobile:
                setMobileColumns();
                break;
        }
    }

    const setPCColumns = () => {
        setMemberOrdersColumns([
            {
                title: <>{translate('Order ID')}</>,
                dataIndex: 'id',
                sorter: (a: IOrder, b: IOrder) => a.id.toString().localeCompare(b.id.toString()),
                render: (id: BigInt) => <a onClick={() => handleOperation(id)}>{id.toString()}</a>,
                fixed: 'left',
            },
            {
                title: <>{translate('Total price')}</>,
                dataIndex: 'totalFinalPrice',
                sorter: (a: IOrder, b: IOrder) => a.totalFinalPrice - b.totalFinalPrice,
            },
            {
                title: <>{translate('Paid')}</>,
                dataIndex: 'isPaid',
                sorter: (a: IOrder, b: IOrder) => a.isPaid === b.isPaid ? 0 : a.isPaid ? 1 : -1,
                render: (isPaid: boolean) => (isPaid ? translate('Yes') : translate('No')),
            },
            {
                title: <>{translate('Order status')}</>,
                dataIndex: 'status',
                sorter: (a: IOrder, b: IOrder) => a.status.localeCompare(b.status),
                render: (status: string) => translate(status),
            },
            {
                title: <>{translate('Create time')}</>,
                dataIndex: 'createdTime',
                sorter: (a: IOrder, b: IOrder) => a.createdTime.localeCompare(b.createdTime),
                render: (createdTime: string) => new Intl.DateTimeFormat(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZoneName: 'short'
                }).format(new Date(createdTime)),
            }
        ]);

        setOrderDetailItemColumns([
            {
                title: <>{translate('Image')}</>,
                dataIndex: 'itemIcon',
                render: (itemIcon: string) => <img src={itemIcon} style={{ margin: '5px', width: '100px', height: '100px' }} />,
                fixed: 'left',
            },
            {
                title: <>{translate('Name')}</>,
                dataIndex: 'itemName',
            },
            {
                title: <>{translate('SpecName')}</>,
                dataIndex: 'itemSpecName',
            },
            {
                title: <>{translate('Price')}</>,
                dataIndex: 'itemSpecPrice',
            },
            {
                title: <>{translate('BuyAmount')}</>,
                dataIndex: 'buyAmount',
            },
        ]);
    }

    const setMobileColumns = () => {
        setScrollX(568);
        setMemberOrdersColumns([
            {
                title: <>{translate('OrderId')}</>,
                dataIndex: 'id',
                sorter: (a: IOrder, b: IOrder) => a.id.toString().localeCompare(b.id.toString()),
                render: (id: BigInt) => <a onClick={() => handleOperation(id)}>{id.toString()}</a>,
                fixed: 'left',
            },
            {
                title: <>{translate('Order Status')}</>,
                dataIndex: 'status',
                sorter: (a: IOrder, b: IOrder) => a.status.localeCompare(b.status),
            },
            {
                title: <>{translate('Create Time')}</>,
                dataIndex: 'createdTime',
                sorter: (a: IOrder, b: IOrder) => a.createdTime.localeCompare(b.createdTime),
                render: (createdTime: string) => new Intl.DateTimeFormat(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZoneName: 'short'
                }).format(new Date(createdTime)),
            }
        ]);
        setOrderDetailItemColumns([
            {
                title: <>{translate('Image')}</>,
                dataIndex: 'itemIcon',
                render: (itemIcon: string) => <img src={itemIcon} style={{ margin: '5px', width: '100px', height: '100px' }} />,
                fixed: 'left',
            },
            {
                title: <>{translate('Name')}</>,
                dataIndex: 'itemName',
            },
            {
                title: <>{translate('SpecName')}</>,
                dataIndex: 'itemSpecName',
            },
            {
                title: <>{translate('Price')}</>,
                dataIndex: 'itemSpecPrice',
            },
            {
                title: <>{translate('BuyAmount')}</>,
                dataIndex: 'buyAmount',
            },
        ]);
    }

    useEffect(() => {
        setColumns(deviceType);
    }, [deviceType, orders]);

    useEffect(() => {
        if (orders && orders.result && orders.result.data && props.orderId) {
            handleOperation(BigInt(props.orderId));
        }
    }, [orders]);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            refetch();
        }
    }), [orders]);

    const renderPaymentDetail = () => {
        const currentOrder = orders?.result?.data.find((order) => order.id.toString() === orderDetailAggregateViewModel?.order.id.toString());
        if (!currentOrder || currentOrder.isCollection) return null;

        if (currentOrder.status === OrderStatus.WaitForPaid) {
            return (
                <Flex justify="center" align="center">
                    <Button type="primary" onClick={() => {
                        paymentSelectorRef.current?.reset();
                        setIsPaymentModalVisible(true);
                    }}>{translate('Apply Payment')}</Button>
                </Flex>
            );
        } else {
            return (
                <Row>
                    <Col span={24}>
                        <Card title={`${translate('Payment')} ${translate('Detail')}`}>
                            <p>{translate('Payment Name')}: {orderDetailAggregateViewModel?.orderPaymentDetailViewModel?.providerName}</p>
                            <p>{translate('IsPaid')}: {orderDetailAggregateViewModel?.orderPaymentDetailViewModel?.isPaid ? translate('Yes') : translate('No')}</p>
                            <p>{translate('CreatedDate')}: {orderDetailAggregateViewModel?.orderPaymentDetailViewModel?.createdDate}</p>
                            <p>{translate('UpdatedDate')}: {orderDetailAggregateViewModel?.orderPaymentDetailViewModel?.updatedDate}</p>
                        </Card>
                    </Col>
                </Row>
            );
        }
    };

    return (
        <>
            <Table
                loading={isLoading}
                bordered={true}
                dataSource={orders?.result?.data}
                columns={memberOrdersColumns}
                pagination={{ position: [bottom] }}
                rowKey="id"
                scroll={{ x: scrollX }}
            />
            <Modal
                title={translate('Order') + ' ' + translate('Detail')}
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => {
                    paymentSelectorRef.current?.reset();
                    setIsModalVisible(false);
                }}
                footer={[
                    <Flex justify="center">
                        <Button key="submit" type="primary" onClick={() => setIsModalVisible(false)}>
                            {translate('Close')}
                        </Button>
                    </Flex>
                ]}
            >
                <>
                    <p>Order ID: {orderDetailAggregateViewModel?.order.id.toString()}</p>
                    <Table
                        dataSource={orderDetailAggregateViewModel?.order.items.map((item) => ({ key: item.itemId, ...item }))}
                        columns={orderDetailItemColumns}
                        pagination={false}
                        scroll={{ x: scrollX }}
                    />
                    <Row>
                        <Col span={24} style={{ marginTop: '10px' }}>
                            <Flex justify="center" align="center">
                                <p>{translate('Total final price')}: {selectedOrder?.totalFinalPrice}</p>
                            </Flex>
                        </Col>
                    </Row>
                    {renderPaymentDetail()}
                    <Modal open={isPaymentModalVisible} footer={null} onCancel={() => setIsPaymentModalVisible(false)}>
                        <PaymentSelector
                            orderId={orderDetailAggregateViewModel?.order.id}
                            isHideSteps={true}
                            nextStep={checkOutContext.nextStep}
                            prevStep={checkOutContext.prevStep}
                            translate={translate}
                            messageApi={messageApi}
                            merchantId={merchantId}
                            paymentMainTypes={checkOutContext.paymentMainTypes || []}
                            portalMerchantPaymentSettings={checkOutContext.portalMerchantPaymentSettings || []}
                            selectedPayment={checkOutContext.selectedPayment}
                            setSelectedPayment={checkOutContext.setSelectedPayment}
                            setPaymentValues={checkOutContext.setPaymentValues}
                            refetchPaymentMainTypes={checkOutContext.refetchPaymentMainTypes}
                            refetchPaymentSettings={checkOutContext.refetchPaymentSettings}
                            sendPaymentRequest={checkOutContext.sendPaymentRequest}
                        />
                    </Modal>
                    <Card title={`${translate('Logistic')} ${translate('Info')}`}>
                        <p>{translate('Name')}: {orderDetailAggregateViewModel?.orderHistoryDetailViewModel?.memberLogisticOption?.displayName}</p>
                        <p>{translate('Address')}: {orderDetailAggregateViewModel?.orderHistoryDetailViewModel?.memberLogisticOption?.displayAddress}</p>
                        <p>{translate('Receiver') + ' ' + translate('Name')}: {selectedOrder?.values['receiverName']}</p>
                        <p>{translate('Receiver') + ' ' + translate('Phone')}: {selectedOrder?.values['receiverPhone']}</p>
                        <p>{translate('Receiver') + ' ' + translate('Email')}: {selectedOrder?.values['receiverEmail']}</p>
                        <p>{translate('IsCollection')}: {selectedOrder?.isCollection ? translate('Yes') : translate('No')}</p>
                    </Card>
                </>
            </Modal>
        </>
    );
});

export default Orders;
