import { BASE_PATH } from '@/constants/routes';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Button, Col, Row } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './Components/CountdownTimer';
import './NotFoundPage.css';

const NotFoundPage = () => {
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();
  function returnClick() {
    navigate(`${BASE_PATH}/`);
  }
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <p>{translate('Sorry')}, {translate('Page Not found.')}</p>
      <Row >
        <Col span={24}>
          <CountdownTimer></CountdownTimer>
        </Col>
      </Row>
      <Row style={{ margin: '10px' }} justify={'center'}>
        <Col span={24}>
          <Button onClick={returnClick}>{translate('Return')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default NotFoundPage
