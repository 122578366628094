import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { Col, Flex, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import Orders from "../MemberPages/Components/Orders";

const AnonymousOrderPages = () => {
    const { deviceType } = useContext(GlobalContext);
    const [orderId, setOrderId] = useState<string | undefined>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const orderId = params.get('orderId');
        if (orderId) {
            setOrderId(orderId);
        }
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Flex justify="center" align="center" style={{ padding: '30px' }}>
                    <Orders orderId={orderId} />
                </Flex>
            </Col>
        </Row>
    )
}

export default AnonymousOrderPages