import JSONBig from 'json-bigint';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { BASE_PATH } from '@/constants/routes';
import { CurrencyCode } from '@/enums/CurrecnyCode';
import { IAddItemToShoppingCartItem } from '@/interfaces/Requests';
import { useAddToShoppingCartApi, useAddToTempShoppingCartApi, useGetItemSpecsApi, useGetItemsByMerchantApi } from '@/lib/api/apis';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { MerchantCheckoutType, MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { SignHelper } from '@/Templates/helpers/SignHelper';
import { IItemSpec, IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { ItemDetailPageTemplateProps } from '@/Templates/TemplateProps';
;

const ItemDetailPage: React.FC = React.memo(() => {
    const signHelper = new SignHelper();
    const {
        sendGAEvent,
        shoppingCartItemCount,
        messageApi,
        isLogin,
        merchantId,
        deviceType,
        merchantPortalOptionSetting,
        merchantPortalStyleSettings,
        tryGetSessionId,
        refreshShoppingCartItemCount,
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const location = useLocation();
    const navigate = useNavigate();

    // 商品相關狀態
    const [itemId, setItemId] = useState<BigInt>(BigInt(0));
    const [item, setItem] = useState<IItemViewModel | undefined>(undefined);

    // 商品規格相關狀態
    const [itemSpecs, setItemSpecs] = useState<IItemSpec[]>([]);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);

    // 購物車項目狀態
    const [shoppingCartItem, setShoppingCartItem] = useState<IAddItemToShoppingCartItem>({
        itemId: BigInt(0),
        itemSpecId: BigInt(0),
        buyAmount: 0,
        currency: CurrencyCode.TWD,
    });

    // 簽名相關狀態
    const [hash, setHash] = useState<string>("");

    // 從 URL 獲取商品 ID 並加載商品信息
    useEffect(() => {
        const loadItemData = () => {
            const queryParams = new URLSearchParams(location.search);
            const newItemId = BigInt(queryParams.get('itemId') || '0');

            if (newItemId !== itemId) {
                setItemId(newItemId);
                resetItemState();
                getItems({
                    merchantId: merchantId,
                    page: 1,
                    pageSize: 1,
                    extraItemIds: [newItemId]
                });
            }
        };

        const resetItemState = () => {
            setItem(undefined);
            setItemSpecs([]);
            setSelectedItemSpec(null);
            setPurchaseQuantity(1);
        };

        loadItemData();
    }, [location.search, merchantId]);

    // 獲取商品信息
    const { mutate: getItems, isLoading: isLoadingItems } = useMutation(useGetItemsByMerchantApi, {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                if (response.result.data.length > 0) {
                    setItem(response.result.data[0]);
                    getItemSpecs(merchantId);
                } else {
                    navigate(`/${merchantId}/notfound`);
                }
            }
        }
    });

    // 獲取商品規格
    const { mutate: getItemSpecs } = useMutation(
        async (merchantId: BigInt) => await useGetItemSpecsApi(merchantId, itemId),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    const data = response.result;
                    setItemSpecs(data);
                    setSelectedItemSpec(data[0] ? data[0] : null);
                } else {
                    messageApi.error(response.message || translate('Operation failed'));
                }
            }
        }
    );

    // 生成簽名
    useEffect(() => {
        const generateSign = async () => {
            const st = merchantPortalOptionSetting?.merchantCheckoutType === MerchantCheckoutType.Normal
                ? `${merchantId}:${JSONBig.stringify(shoppingCartItem)}`
                : `${merchantId}:${tryGetSessionId}:${JSONBig.stringify(shoppingCartItem)}`;
            const sign = await signHelper.generateDynamicTimeSignAsync(st, new Date());
            setHash(sign);
        };
        generateSign();
    }, [tryGetSessionId, shoppingCartItem, merchantId, merchantPortalOptionSetting?.merchantCheckoutType]);

    // 處理添加到購物車成功
    const handleAddToCartSuccess = useCallback((data: any) => {
        if (data?.isSuccess) {
            messageApi.success(translate('Operation success'));
            setPurchaseQuantity(1);
        } else {
            messageApi.error(translate(data?.message));
        }
    }, [messageApi, translate]);

    // 添加到臨時購物車
    const addToTempShoppingCartMutation = useMutation(
        async () => await useAddToTempShoppingCartApi({
            merchantId: merchantId,
            sessionId: tryGetSessionId(),
            addItemToShoppingCartItem: shoppingCartItem,
            hash: hash
        }),
        {
            onSuccess: handleAddToCartSuccess
        }
    );

    // 添加到購物車
    const addToShoppingCartMutation = useMutation(
        async () => await useAddToShoppingCartApi({
            addItemToShoppingCartItem: shoppingCartItem,
            hash: hash
        }),
        {
            onSuccess: handleAddToCartSuccess
        }
    );

    // 處理規格選擇變更
    const handleSelectChange = useCallback((value: string) => {
        const itemSpecId = BigInt(value);
        const itemSpec = itemSpecs.find(item => item.id.toString() === itemSpecId.toString());
        setSelectedItemSpec(itemSpec || null);
        setPurchaseQuantity(itemSpec?.stockAmount || 0 > 0 ? 1 : 0);
    }, [itemSpecs]);

    // 更新購物車項目
    useEffect(() => {
        setShoppingCartItem({
            itemId: item?.id || BigInt(0),
            itemSpecId: selectedItemSpec?.id || BigInt(0),
            buyAmount: purchaseQuantity,
            currency: selectedItemSpec?.currency || CurrencyCode.TWD,
        });
    }, [purchaseQuantity, selectedItemSpec, item]);

    // 處理添加到購物車
    const handleAddToShoppingCart = useCallback(async () => {
        sendGAEvent('add_to_cart', {
            currency: shoppingCartItem.currency,
            value: shoppingCartItem.buyAmount * (selectedItemSpec?.sellPrice || 0),
            items: [
                {
                    item_id: item?.id.toString(),
                    item_name: item?.name,
                    affiliation: merchantPortalOptionSetting?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSetting?.merchantName,
                    item_category: selectedItemSpec?.name,
                    price: selectedItemSpec?.sellPrice,
                    quantity: shoppingCartItem.buyAmount,
                }
            ]
        });
        if (isLogin) {
            await addToShoppingCartMutation.mutateAsync();
        } else {
            await addToTempShoppingCartMutation.mutateAsync();
        }
        await refreshShoppingCartItemCount(isLogin);
    }, [sendGAEvent, shoppingCartItem, selectedItemSpec, item, isLogin, addToShoppingCartMutation, addToTempShoppingCartMutation, refreshShoppingCartItemCount, merchantPortalOptionSetting?.merchantName]);

    const portalStyle = useMemo(() => merchantPortalStyleSettings.find(
        x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'ItemDetailPage'
    ), [merchantPortalStyleSettings]);

    const TemplateComponent = useMemo(() => dynamic<ItemDetailPageTemplateProps>(() =>
        import(`../../Templates/Shop/Pages/ItemDetailPage/Template_${portalStyle?.style || '0'}.tsx`),
        {
            loading: () => <></>,
            ssr: false
        }
    ), [portalStyle?.style]);

    if (isLoadingItems || !item) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            deviceType={deviceType}
            portalOptionSettingViewModel={merchantPortalOptionSetting}
            translate={translate}
            portalStyleSettings={merchantPortalStyleSettings}
            isPreviewMode={false}
            messageApi={messageApi}
            item={item}
            config={portalStyle?.styleSetting || {}}
            itemSpecs={itemSpecs}
            selectedItemSpec={selectedItemSpec}
            purchaseQuantity={purchaseQuantity}
            shoppingCartItemCount={shoppingCartItemCount}
            handleSelectChange={handleSelectChange}
            setPurchaseQuantity={setPurchaseQuantity}
            handleAddToShoppingCart={handleAddToShoppingCart}
            isAddToShoppingCartLoading={addToShoppingCartMutation.isLoading}
            isAddToTempShoppingCartLoading={addToTempShoppingCartMutation.isLoading}
        />
    );
});

export default ItemDetailPage;
