import { BASE_PATH } from "@/constants/routes";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import queryString from 'query-string';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/*
Response Example:
https://localhost:5173/google-login-callback?state=https%3A%2F%2Fportal.localhost%3A5173%2F1852955603035623424&code=4%2F0AeanS0YZubwv8g0xKNkAjIXqpUu-DGg7B7prt2wV8Wxe5YuAfTLofIoiphISTfVDfWp0Mw&scope=email+profile+openid+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&authuser=0&prompt=consent
*/
const GoogleCallbackPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const { code, state } = queryString.parse(window.location.search);
        if (code) {
            try {
                const origin = state ? decodeURI(state as string) : '';
                var redirectUrl = `${decodeURI(origin)}/proxy-login?method=google&token=${code}`;
                console.log(redirectUrl);
                window.location.replace(`${decodeURI(origin)}/proxy-login?method=google&token=${code}`);
            } catch (error) {
                console.error('Google 登入錯誤:', error);
            }
        } else {
            console.log('無法取得 code');
            navigate(`${BASE_PATH}/error`);
        }
    }, []);

    return (
        <LoadingComponent></LoadingComponent>
    );
}

export default GoogleCallbackPage;