import { DeviceType } from "@/Templates/enums/templateEnums";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Tabs } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckOutProvider } from "../../Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";
import MemberInto, { IMemberInfoRef } from "./Components/MemberInto";
import Orders, { IOrdersRef } from "./Components/Orders";

const MemberInfoPage = () => {
  const { deviceType, merchantId, isLogin, tryGetSessionId, messageApi } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState<string>('');
  const memberInfoRef = useRef<IMemberInfoRef>(null);
  const ordersRef = useRef<IOrdersRef>(null);
  const params = new URLSearchParams(window.location.search);
  const [orderId, setOrderId] = useState<string | undefined>(params.get('orderId') || undefined);
  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    //Init
    if (activeKey === '') {
      if (orderId) {
        setActiveKey('Orders');
      }
      else {
        setActiveKey('MemberInfo');
      }
    }

    if (activeKey === 'MemberInfo') {
      navigate(location.pathname);
      memberInfoRef.current?.onRefresh();
    } else if (activeKey === 'Orders') {
      ordersRef.current?.onRefresh();
    }
  }, [activeKey]);

  const items = [
    {
      key: 'MemberInfo',
      label: translate('MemberInfo'),
      children: <MemberInto ref={memberInfoRef} />
    },
    {
      key: 'Orders',
      label: translate('Orders'),
      children: (
        <CheckOutProvider
          translate={translate}
          merchantId={merchantId}
          isLogin={isLogin}
          tryGetSessionId={tryGetSessionId}
          messageApi={messageApi}
        >
          <Orders ref={ordersRef} orderId={orderId} />
        </CheckOutProvider>
      )
    }
  ];

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={handleTabChange}
        style={{ padding: '30px' }}
        tabPosition={deviceType === DeviceType.Mobile ? 'top' : 'left'}
        centered={deviceType === DeviceType.Mobile}
        items={items}
      />
    </div>
  );
};

export default MemberInfoPage;