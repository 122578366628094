import { ITranslationContext, ITranslationContextProps } from '@/interfaces/ITranslationContext';
import { useGetGetTranslationsApi, useGetMerchantPortalOptionSettingApi, useGetPortalDefaultLanguageApi, useGetSupportedLanguagesApi } from '@/lib/api/apis';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { enUS, zhCN } from 'date-fns/locale';
import { useMutation } from 'react-query';
import { GlobalContext } from './GlobalContext';

// 这里是i18next初始化和配置的代码
i18n
    .use(initReactI18next)
    .init({
        // 资源，语言配置等
    });

export const TranslationContext = React.createContext<ITranslationContext>(null!);

export const TranslationProvider: React.FC<ITranslationContextProps> = ({ children }): React.ReactNode => {
    const defaultNameSpace = 'Global';
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { t, i18n } = useTranslation(defaultNameSpace);
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['en_US', 'zh_TW']);
    const [language, setLanguage] = useState<string>('en_US');
    const [timeFormatLocale, setTimeFormatLocale] = useState<any>(enUS);

    const customTranslate = useCallback((key: string, nameSpace: string = defaultNameSpace): string => {
        if (!key) return '';
        var translatedString = i18n.t(key.toLowerCase(), { ns: nameSpace });
        return translatedString === key.toLowerCase() ? key : translatedString;
    }, [i18n, defaultNameSpace]);

    const getCountrySelectionDisplayName = useCallback((country: string): string => {
        switch (country) {
            case 'TW':
                return 'TW (台灣)';
            default:
                return country;
        }
    }, []);

    const { mutate: initPortalLanguage } = useMutation(async () => await useGetMerchantPortalOptionSettingApi(merchantId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setLanguage(response.result.portalLanguage);
            }
            else {
                messageApi.error(t(response.message || 'Operation failed'));
            }
        }
    });

    useEffect(() => {
        useGetSupportedLanguagesApi()
            .then(response => {
                if (response.isSuccess && response.result) {
                    setSupportedLanguages(response.result);
                }
            })
            .catch(error => {
                console.log(error);
            });

        useGetPortalDefaultLanguageApi(merchantId)
            .then(response => {
                if (response.isSuccess && response.result) {
                    setLanguage(response.result);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [merchantId]);

    useEffect(() => {
        useGetGetTranslationsApi(language)
            .then(response => {
                if (!response.isSuccess || !response.result) {
                    return;
                }
                var dtoTranslation: Record<string, {}> = {};
                response.result.forEach((translation) => {
                    dtoTranslation[translation.nameSpace] = { ...dtoTranslation[translation.nameSpace], [translation.key]: translation.value }
                });
                for (const key in dtoTranslation) {
                    i18n.addResourceBundle(language, key, dtoTranslation[key], true, true);
                }
                i18n.changeLanguage(language);
            })
            .catch(error => {
                console.log(error);
            });
    }, [language, i18n]);

    useEffect(() => {
        setTimeFormatLocale(i18n.language === 'zh_TW' ? zhCN : enUS);
    }, [i18n.language]);

    const contextValue = useMemo(() => ({
        seti18nLanguage: setLanguage,
        i18nLanguage: i18n.language,
        translate: customTranslate,
        timeFormatLocale,
        getCountrySelectionDisplayName,
        initPortalLanguage
    }), [i18n.language, timeFormatLocale, initPortalLanguage, customTranslate, getCountrySelectionDisplayName]);

    return (
        <TranslationContext.Provider value={contextValue}>
            {children}
        </TranslationContext.Provider>
    );
};