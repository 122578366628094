import facebookIcon from "@/assets/Facebook/facebook.png";
import { IsFreeDomain } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import LineLogin from "@/lib/thirdParty/LineLogin";
import ThirdPartyTokenLoginFlow from "@/lib/thirdParty/ThirdPartyTokenLoginFlow";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { GoogleOutlined } from "@ant-design/icons";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { Button, Flex, Image } from "antd";
import { useContext } from "react";
// 第三方登入屬性介面
interface ThirdPartyLoginProps {
    isLoading: boolean;
    googleLoginBtn: () => void;
    handleThirdPartyTokenLogin: (token: string, method: string, state?: string) => void;
    translate: (key: string) => string;
    merchantId: BigInt;
    tryGetSessionId: () => string;
    deviceType: DeviceType;
}

// 第三方登入組件
const ThirdPartyLogin = ({
    isLoading,
    googleLoginBtn,
    handleThirdPartyTokenLogin,
    translate,
    merchantId,
    tryGetSessionId,
    deviceType
}: ThirdPartyLoginProps) => {
    const { messageApi } = useContext(GlobalContext);
    const originHost = IsFreeDomain ? `${window.location.origin}/${merchantId}` : window.location.origin;
    return (
        <>
            <Flex wrap="wrap" justify="center" gap={16}>
                <Button
                    loading={isLoading}
                    onClick={googleLoginBtn}>
                    <GoogleOutlined />Google
                </Button>
                <FacebookLogin
                    appId={`${process.env.FacebookAppId}`}
                    scope="public_profile, email"
                    useRedirect
                    dialogParams={{
                        redirect_uri: `${process.env.FacebookRedirectUri}`,
                        state: originHost,
                        response_type: 'token'
                    }}
                    render={({ onClick, logout }) => (
                        <Button
                            loading={isLoading}
                            onClick={onClick}>
                            <Image
                                width={20}
                                src={facebookIcon}
                                preview={false}
                            />
                            Facebook
                        </Button>
                    )}
                />
                <LineLogin isLoading={isLoading} />
                <ThirdPartyTokenLoginFlow
                    tokenCallback={handleThirdPartyTokenLogin}
                />
            </Flex>
        </>
    );
};

export default ThirdPartyLogin; 