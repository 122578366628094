import { BASE_PATH } from "@/constants/routes";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import queryString from 'query-string';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LineCallbackPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const { code, state } = queryString.parse(window.location.search);
        if (code) {
            try {
                const origin = state ? decodeURI(state as string) : '';
                window.location.replace(`${decodeURI(origin)}/proxy-login?method=line&token=${code}`);
            } catch (error) {
                console.error('Error during LINE login:', error);
            }
        }
        else {
            navigate(`${BASE_PATH}/error`);
        }
    }, []);
    return (
        <LoadingComponent></LoadingComponent>
    );
}

export default LineCallbackPage;