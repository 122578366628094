import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";
import { NavBarTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingCompoent";

const NavBar: React.FC<NavBarTemplateProps> = React.memo(({
    merchantId,
    deviceType,
    portalOptionSettingViewModel,
    portalStyleSettings,
    translate,
    messageApi,
    config,
    BASE_PATH,
    isHideNavBar,
    merchantPortalOptionSetting,
    navBarHeight,
    shoppingCartItemCount,
    isLogin,
    setIsLogin,
    refreshShoppingCartItemCount
}) => {
    const portalStyle = portalStyleSettings.find(x => x.type === MerchantPortalStyleSettingType.Components && x.name === MerchantPortalStyleShopComponents.NavBar);
    const navigate = useNavigate();
    const location = useLocation();

    // 實現 handleLogout
    const handleLogout = useCallback(() => {
        localStorage.removeItem("JWTToken");
        setIsLogin?.(false);
        refreshShoppingCartItemCount?.(false);
        //if location is not root path, then navigate to root path
        if (location.pathname !== `${BASE_PATH}/`) {
            navigate(`${BASE_PATH}/`);
        }
    }, [setIsLogin, refreshShoppingCartItemCount, navigate, BASE_PATH]);

    const TemplateComponent = useMemo(() => {
        return dynamic<NavBarTemplateProps>(() =>
            import(`../../Templates/Shop/Components/NavBar/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, [portalStyle, isLogin]);

    return <TemplateComponent
        merchantId={merchantId}
        deviceType={deviceType}
        messageApi={messageApi}
        isPreviewMode={false}
        config={config}
        translate={translate}
        portalStyleSettings={portalStyleSettings}
        portalOptionSettingViewModel={portalOptionSettingViewModel}
        BASE_PATH={BASE_PATH}
        isHideNavBar={isHideNavBar}
        merchantPortalOptionSetting={merchantPortalOptionSetting}
        navBarHeight={navBarHeight}
        shoppingCartItemCount={shoppingCartItemCount}
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        refreshShoppingCartItemCount={refreshShoppingCartItemCount}
        handleLogout={handleLogout}
    />;
});

export default NavBar;
