import { BASE_PATH } from "@/constants/routes";
import { IBatchRemoveItemsFromShoppingCartRequest, IBatchRemoveItemsFromTempShoppingCartRequest } from "@/interfaces/Requests";
import { useBatchRemoveItemsFromShoppingCartApi, useBatchRemoveItemsFromTempShoppingCartApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { IShoppingCartItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { ShoppingCartPageTemplateProps } from "@/Templates/TemplateProps";
import { Modal } from 'antd';
import dynamic from "next/dynamic";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { CheckOutProvider, useCheckOut } from "../../Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";

const ShoppingCartPage: React.FC = React.memo(() => {
  const {
    merchantId,
    deviceType,
    merchantPortalOptionSetting,
    merchantPortalStyleSettings,
    messageApi,
    isLogin,
    tryGetSessionId,
    shoppingCartItemCount,
    refreshShoppingCartItemCount,
    sendGAEvent,
    refetchMerchantPortalOptionSetting,
  } = useContext(GlobalContext);
  const { translate, getCountrySelectionDisplayName } = useContext(TranslationContext);
  const {
    currentStep,
    setCurrentStep,
    nextStep,
    prevStep,
    selectedItems,
    setSelectedItems,
    selectedPayment,
    setSelectedPayment,
    paymentValues,
    setPaymentValues,
    selectedLogistic,
    setSelectedLogistic,
    selectedMemberLogisticOption,
    setSelectedMemberLogisticOption,
    logisticValues,
    setLogisticValues,
    userWantReservePay,
    setUserWantReservePay,
    receiverInfos,
    setReceiverInfos,
    shoppingCartData,
    refetchShoppingCart,
    isLoadingShoppingCart,
    paymentMainTypes,
    portalMerchantPaymentSettings,
    logisticsMainTypes,
    logisticMethods,
    memberLogisticOptions,
    mutateMemberLogisticOptions,
    handleAddMemberLogisticOption,
    handleDeleteMemberLogisticOption,
    sendPaymentRequest,
    handleAddStoreAddressOption,
    refetchPaymentMainTypes,
    refetchPaymentSettings,
    refetchLogisticsMainTypes,
    refetchLogisticMethods,
    isLoadingPaymentMainTypes,
    isLoadingPaymentSettings,
    handleSelectAll,
    handleSelectItem,
    memberSelectedParameters,
    setMemberSelectedParameters,
    isAddingStoreAddress,
    isDeletingLogisticOption
  } = useCheckOut();

  const handleDeleteShoppingCartItems = useCallback(async (items: IShoppingCartItemViewModel[]) => {
    Modal.confirm({
      title: translate('Confirm'),
      content: `${translate('Are you sure you want to delete this option')}?`,
      okText: translate('Yes'),
      cancelText: translate('No'),
      onOk: async () => {
        const shoppingCartItems = [...items];
        let response;
        if (isLogin) {
          const request: IBatchRemoveItemsFromShoppingCartRequest = { requests: [{ shoppingCartItems }] };
          response = await useBatchRemoveItemsFromShoppingCartApi(request);
        } else {
          const tempRequest: IBatchRemoveItemsFromTempShoppingCartRequest = {
            requests: [{
              sessionId: tryGetSessionId(),
              merchantId: merchantId,
              shoppingCartItems
            }]
          };
          response = await useBatchRemoveItemsFromTempShoppingCartApi(tempRequest);
        }

        if (response.isSuccess) {
          messageApi.success(translate('Operation success'));
          sendGAEvent('remove_from_cart', {
            currency: shoppingCartItems[0].currency,
            value: shoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
            items: shoppingCartItems.map((itemSpec) => ({
              item_id: itemSpec?.itemId.toString(),
              item_name: itemSpec?.itemName,
              affiliation: merchantPortalOptionSetting?.merchantName,
              coupon: "None",
              discount: 0,
              index: 0,
              item_brand: merchantPortalOptionSetting?.merchantName,
              item_category: itemSpec!.itemSpecName,
              price: itemSpec!.itemSpecPrice,
              quantity: itemSpec.buyAmount
            })),
          });
          setSelectedItems(prevItems => prevItems.filter(i => !shoppingCartItems.some(item => (i.itemId === item.itemId) && (i.itemSpecId === item.itemSpecId))));
          refetchShoppingCart();
          refreshShoppingCartItemCount(isLogin);
        } else {
          messageApi.error(translate(response.message || 'Operation failed'));
        }
      },
    });
  }, [isLogin, merchantId, tryGetSessionId, translate, messageApi, sendGAEvent, merchantPortalOptionSetting, refetchShoppingCart, refreshShoppingCartItemCount, setSelectedItems]);

  const portalStyle = merchantPortalStyleSettings.find(
    x => x.type === MerchantPortalStyleSettingType.Pages && x.name === MerchantPortalStyleShopPages.ShoppingCartPage
  );

  const TemplateComponent = useMemo(() => {
    return dynamic<ShoppingCartPageTemplateProps>(() =>
      import(`../../Templates/Shop/Pages/ShoppingCartPage/Template_${portalStyle?.style || '0'}.tsx`),
      {
        loading: () => <LoadingComponent />,
        ssr: false
      }
    );
  }, [portalStyle?.style]);

  useEffect(() => {
    mutateMemberLogisticOptions(isLogin);
  }, [isLogin]);

  if (isLoadingShoppingCart || isLoadingPaymentMainTypes || isLoadingPaymentSettings) {
    return <LoadingComponent />;
  }
  return (
    <CheckOutProvider
      merchantId={merchantId}
      isLogin={isLogin}
      tryGetSessionId={tryGetSessionId}
      messageApi={messageApi}
      translate={translate}>
      <TemplateComponent
        BASE_PATH={BASE_PATH}
        merchantId={merchantId}
        deviceType={deviceType}
        portalOptionSettingViewModel={merchantPortalOptionSetting}
        portalStyleSettings={merchantPortalStyleSettings}
        translate={translate}
        messageApi={messageApi}
        isPreviewMode={false}
        config={portalStyle?.styleSetting || {}}
        shoppingCartData={shoppingCartData}
        refetch={refetchShoppingCart}
        isLogin={isLogin}
        tryGetSessionId={tryGetSessionId}
        shoppingCartItemCount={shoppingCartItemCount}
        refreshShoppingCartItemCount={refreshShoppingCartItemCount}
        paymentMainTypes={paymentMainTypes}
        portalMerchantPaymentSettings={portalMerchantPaymentSettings}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentValues={paymentValues}
        setPaymentValues={setPaymentValues}
        sendPaymentRequest={sendPaymentRequest}
        refetchPaymentMainTypes={refetchPaymentMainTypes}
        refetchPaymentSettings={refetchPaymentSettings}
        logisticsMainTypes={logisticsMainTypes}
        logisticMethods={logisticMethods}
        memberLogisticOptions={memberLogisticOptions}
        selectedLogistic={selectedLogistic}
        setSelectedLogistic={setSelectedLogistic}
        selectedMemberLogisticOption={selectedMemberLogisticOption}
        setSelectedMemberLogisticOption={setSelectedMemberLogisticOption}
        logisticValues={logisticValues}
        setLogisticValues={setLogisticValues}
        userWantReservePay={userWantReservePay}
        setUserWantReservePay={setUserWantReservePay}
        receiverInfos={receiverInfos}
        setReceiverInfos={setReceiverInfos}
        handleAddMemberLogisticOption={handleAddMemberLogisticOption}
        handleDeleteMemberLogisticOption={handleDeleteMemberLogisticOption}
        handleAddStoreAddressOption={handleAddStoreAddressOption}
        handleDeleteShoppingCartItems={handleDeleteShoppingCartItems}
        merchantPortalOptionSetting={merchantPortalOptionSetting}
        handleSelectAll={handleSelectAll}
        handleSelectItem={handleSelectItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        sendGAEvent={sendGAEvent}
        getCountrySelectionDisplayName={getCountrySelectionDisplayName}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nextStep={nextStep}
        prevStep={prevStep}
        memberSelectedParameters={memberSelectedParameters}
        setMemberSelectedParameters={setMemberSelectedParameters}
        refetchMerchantPortalOptionSetting={refetchMerchantPortalOptionSetting}
        isAddingStoreAddress={isAddingStoreAddress}
        isDeletingLogisticOption={isDeletingLogisticOption}
      />
    </CheckOutProvider>
  );
});

export default ShoppingCartPage;
