export enum MerchantPortalStyleSettingType {
    None = 'None',
    Components = 'Components',
    Pages = 'Pages'
}

export enum NavBarClickType {
    None = 'None',
    RedirectPage = 'RedirectPage',
    ItemSearch = 'ItemSearch',
    RedirectItemDetailPage = 'RedirectItemDetailPage',
}

export enum FrontEndDisplayType {
    /// <summary>
    /// 頁面跳轉
    /// </summary>
    PageRedirectURL = 'PageRedirectURL',
    /// <summary>
    /// 頁面FormPost組合打出
    /// </summary>
    PageFormPost = 'PageFormPost',
    /// <summary>
    /// 新開頁面
    /// </summary>
    OpenNewPage = 'OpenNewPage',
    Html = 'Html',
}

export enum ThirdPartyPaymentCreateResponseTypeEnum {
    FormPost = "FormPost",
    Redirect = "Redirect",
    QRCode = "QRCode",
    Html = "Html"
}

export enum LogisticsMainType {
    None = 'None',
    AllInOneIntegration = 'AllInOneIntegration',
    ConvenienceStore = 'ConvenienceStore',
    HomeDelivery = 'HomeDelivery',
    MerchantHandle = 'MerchantHandle',
}

export enum ItemSearchSubType {
    None = 'None',
    Category = 'Category',
    ItemTag = 'ItemTag',
    Item = 'Item',
}

export enum MerchantCheckoutType {
    // 需註冊
    Normal = "Normal",
    // 允許加入臨時購物車，結帳需要會員
    AllowTempShoppingCart = "AllowTempShoppingCart",
    // 允許不註冊結帳
    AllowNoneRegist = "AllowNoneRegist",
}

export enum MarqueeType {
    /// <summary>
    /// 商戶主頁
    /// </summary>
    MerchantMain = 'MerchantMain',
    /// <summary>
    /// 系統使用
    /// </summary>
    SystemUsage = 'SystemUsage',
}

export enum MerchantPortalStyleShopPages {
    LandingPage = 'LandingPage',
    HomePage = 'HomePage',
    SearchPage = 'SearchPage',
    ItemDetailPage = 'ItemDetailPage',
    ShoppingCartPage = 'ShoppingCartPage',
    ContactUsPage = 'ContactUsPage',
    PrivacyPolicyPage = 'PrivacyPolicyPage',
    TermsOfServicePage = 'TermsOfServicePage'
}

export enum MerchantPortalStyleShopComponents {
    NavBar = 'NavBar',
    Marquee = 'Marquee',
    ItemCard = 'ItemCard',
    ScrollToTopBtn = 'ScrollToTopBtn',
}

export enum DeviceType {
    Mobile = 'Mobile',
    LapTop = 'LapTop',
    Desktop = 'Desktop',
}

export enum ConfigFieldType {
    Color = 'Color',
    Select = 'Select',
    Number = 'Number',
    Input = 'Input',
    Upload = 'Upload',
    SubMenu = 'SubMenu',
    Pages = 'Pages',
    ItemTags = 'ItemTags',
    Categories = 'Categories',
    Items = 'Items',
    NavBarOption = 'NavBarOption',
}

// 定義步驟枚舉
export enum CheckOutStep {
    SelectItem = 0,
    Logistic = 1,
    Payment = 2,
    Confirm = 3
}

export enum LogisticOrderLimitType {
    /// <summary>
    /// 無
    /// </summary>
    None = 'None',
    /// <summary>
    /// 冷凍訂單
    /// </summary>
    Frozen = 'Frozen'
}

export enum LogisticReceviePointType {
    HomeDelivery = 'HomeDelivery',
    ConvenienceStore = 'ConvenienceStore',
    ProviderLocalStore = 'ProviderLocalStore',
}

export enum LogisticSenderPointType {
    HomeDelivery = 'HomeDelivery',
    ConvenienceStore = 'ConvenienceStore',
    ProviderLocalStore = 'ProviderLocalStore',
}

//國家編碼
export enum ISO3166_1Alpha2 {
    TW = 'TW',
    CN = 'CN',
    HK = 'HK',
    US = 'US',
    MY = 'MY',
    PH = 'PH',
    JP = 'JP',
}

