import { BASE_PATH } from "@/constants/routes";
import { LoginTypeEnum } from "@/enums/Enums";
import { useMemberRegistApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { IRegistRequestDto } from "@/Templates/interfaces/templatesInterfaces";
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Image, Input, InputRef, Tabs } from "antd";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const RegistPage = () => {
  const {
    merchantPortalOptionSetting,
    messageApi,
    setIsHideNavBar,
    setIsHideFooter,
    merchantId,
    sendGAEvent,
    deviceType
  } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();
  const [register, set_register] = useState<IRegistRequestDto>({
    merchantId: merchantId,
    userName: "",
    email: "",
    loginType: LoginTypeEnum.Email,
    phoneNumber: "",
    hashPassword: "",
    confirmPassword: "",
  });
  const [form] = Form.useForm();
  const mailRef = useRef<InputRef>(null);
  const formValuesChange = (fieldValues: any, allValues: any) => {
    set_register({ ...register, ...fieldValues });
  };
  const validateConfirmPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue("hashPassword")) {
      return Promise.reject(translate('The two passwords that you entered do not match'));
    }
    return Promise.resolve();
  };
  const { mutate: registMutate, isLoading } = useMutation(async (request: IRegistRequestDto) => useMemberRegistApi(request), {
    onSuccess: (response) => {
      if (response.isSuccess) {
        sendGAEvent(
          'sign_up', {
          method: 'Platform',
        }
        );
        messageApi.success(translate('Operation success')).then(() => navigate(`${BASE_PATH}/login`));
      }
      else {
        messageApi.error(translate(response.message || 'Operation failed'));
      }
    }
  });

  const onFinish = (value: any) => {
    var request: IRegistRequestDto = {
      ...register,
      hashPassword: MD5(register.hashPassword).toString().toLowerCase(),
    };
    registMutate(request);
  };

  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.Email);
  const emailRef = useRef<InputRef>(null);
  const phoneRef = useRef<InputRef>(null);

  const handleTabChange = (type: string) => {
    form.resetFields();
    setLoginType(type as LoginTypeEnum);
    set_register(prev => ({
      ...prev,
      loginType: type === LoginTypeEnum.Email ? LoginTypeEnum.Email : LoginTypeEnum.PhoneNumber
    }));
  };

  useEffect(() => {
    if (loginType === LoginTypeEnum.Email) {
      emailRef.current?.focus();
    } else {
      phoneRef.current?.focus();
    }
  }, [loginType]);

  useEffect(() => {
    setIsHideNavBar(true);
    setIsHideFooter(true);
    return () => {
      setIsHideNavBar(false);
      setIsHideFooter(false);
    }
  }, []);

  return (
    <div className={`flex justify-center ${deviceType === DeviceType.Mobile ? 'h-auto' : 'min-h-screen items-center'}`} >
      <div className="bg-white  p-8 rounded-lg shadow-[0_-10px_50px_-12px_rgba(0,0,0,0.25),0_10px_50px_-15px_rgba(0,0,0,0.3)] w-full max-w-md" >
        <Flex justify="center" align="middle" className="mb-6">
          <Image
            onClick={() => navigate(`${BASE_PATH}/`)}
            src={merchantPortalOptionSetting?.merchantIconPath}
            width={100}
            preview={false}
          />
        </Flex>
        <Tabs
          activeKey={loginType}
          onChange={handleTabChange}
          centered
          items={[
            {
              key: LoginTypeEnum.Email,
              label: translate('Email Register'),
            },
            {
              key: LoginTypeEnum.PhoneNumber,
              label: translate('Phone Register'),
            },
          ]}
        />

        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={formValuesChange}
          layout="vertical"
          className="mt-6"
        >
          <Form.Item
            name="userName"
            rules={[
              { required: true, message: translate('is required') },
              { whitespace: true, message: translate('is required') }
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={translate('UserName')}
              autoComplete="off"
            />
          </Form.Item>

          {loginType === LoginTypeEnum.Email ? (
            <Form.Item
              name="email"
              rules={[
                { required: true, message: translate('is required') },
                {
                  pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: translate("Must be a valid email format"),
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder={translate('Email')}
                ref={emailRef}
                autoComplete="off"
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="phoneNumber"
              rules={[
                { required: true, message: translate('is required') },
                {
                  pattern: /^09[0-9]{8}$/,
                  message: translate("Must be a valid phone number format"),
                },
              ]}
            >
              <Input
                prefix={<PhoneOutlined />}
                placeholder={translate('PhoneNumber')}
                ref={phoneRef}
                autoComplete="off"
              />
            </Form.Item>
          )}

          <Form.Item
            name="hashPassword"
            rules={[
              { required: true, message: translate('is required') },
              {
                pattern: /^.{8,24}$/,
                message: translate('Password must be between 8 and 24 characters')
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={translate('Password')}
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            name="confirm-password"
            rules={[
              {
                required: true,
                message: translate('Must match the first password input field'),
              },
              {
                validator: validateConfirmPassword,
              },
            ]}
          >
            <Input.Password
              className="w-full"
              prefix={<LockOutlined />}
              placeholder={translate('Confirm password')}
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
            >
              {translate('Regist')}
            </Button>
          </Form.Item>
        </Form>

        <Flex justify="center" className="mt-4">
          <span className="text-gray-600">
            {translate('Already have an account') + '?'}
            <Button
              type="link"
              onClick={() => navigate(`${BASE_PATH}/login`)}
              className="ml-2"
            >
              {translate('Login now')}
            </Button>
          </span>
        </Flex>
      </div>
    </div>
  );
};

export default RegistPage;
