import { BASE_PATH } from '@/constants/routes';
import { useGetItemsByMerchantApi, useGetQueryOptionsByMerchantIdApi } from '@/lib/api/apis';
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { IItemViewModel, IPaginationResponse, IPortalQueryItemByMerchantRequest } from '@/Templates/interfaces/templatesInterfaces';
import { PagesTemplateProps } from "@/Templates/TemplateProps";
import { Form } from 'antd';
import JSONBig from 'json-bigint';
import dynamic from 'next/dynamic';
import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from 'react-router-dom';

const SearchPage: React.FC = () => {
    const {
        merchantId,
        deviceType,
        messageApi,
        merchantPortalOptionSetting,
        merchantPortalStyleSettings,
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const [queryRequest, setQueryRequest] = useState<IPortalQueryItemByMerchantRequest | undefined>();
    const [itemVMs, setItemVMs] = useState<IPaginationResponse<IItemViewModel[]>>();


    const { data: queryOptions, isLoading: queryOptionLoading } = useQuery(
        'GetQueryOptionsByMerchantId',
        () => useGetQueryOptionsByMerchantIdApi(merchantId),
        {
            onError: (error) => {
                messageApi.error(translate('Failed to load search options'));
            }
        }
    );

    const { mutate: searchItems, isLoading: queryItemLoading } = useMutation(
        'SearchItems',
        (request: IPortalQueryItemByMerchantRequest) => useGetItemsByMerchantApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    setItemVMs(response.result);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            },
            onError: (error) => {
                messageApi.error(translate('Failed to perform search'));
            }
        }
    );

    useEffect(() => {
        const parseQueryParameter = () => {
            const queryParams = new URLSearchParams(location.search);
            const query = queryParams.get('query');
            if (query === null) {
                form.resetFields();
                return;
            }
            try {
                const request = JSONBig.parse(decodeURIComponent(query)) as IPortalQueryItemByMerchantRequest;
                form.setFieldsValue({
                    fuzzingName: request.fuzzingName,
                    categoryIds: request.categoryIds?.map(id => id.toString()),
                    itemTagIds: request.extraItemTagIds?.map(id => id.toString()),
                    extraItemIds: request.extraItemIds?.map(id => id.toString()),
                });
                setQueryRequest(request);
                searchItems(request);
            } catch {
                form.resetFields();
            }
        };

        if (queryOptions) {
            parseQueryParameter();
        }
    }, [location.search, queryOptions, form, searchItems]);

    const TemplateComponent = useMemo(() => {
        return dynamic<PagesTemplateProps>(() =>
            import(`../../Templates/Shop/Pages/SearchPage/Template_${merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'SearchPage')[0]?.style || '0'}.tsx`),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, [merchantPortalStyleSettings]);

    const memoizedQueryState = useMemo(() => ({
        options: queryOptions,
        form: form,
        request: queryRequest,
        setRequest: setQueryRequest,
        search: searchItems,
        loading: queryItemLoading,
        fuzzingName: form.getFieldValue('fuzzingName'),
        categoryIds: form.getFieldValue('categoryIds'),
        itemTagIds: form.getFieldValue('itemTagIds'),
        onFormValuesChange: (changedValues, allValues) => {
            const newRequest: IPortalQueryItemByMerchantRequest = {
                merchantId: merchantId,
                fuzzingName: allValues.fuzzingName,
                categoryIds: allValues.categoryIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                extraItemTagIds: allValues.itemTagIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                extraItemIds: allValues.extraItemIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
            };
            setQueryRequest(newRequest);
        },
        onSearch: () => {
            if (queryRequest) {
                searchItems(queryRequest);
            }
        },
        onReset: () => {
            const newRequest: IPortalQueryItemByMerchantRequest = {
                merchantId: merchantId,
                fuzzingName: '',
                categoryIds: [],
                extraItemTagIds: [],
            };
            setQueryRequest(newRequest);
        },
        isLoading: queryOptionLoading || queryItemLoading
    }), [queryOptions, form, queryRequest, setQueryRequest, searchItems, queryItemLoading, merchantId, queryOptionLoading]);

    if (!TemplateComponent || !merchantPortalOptionSetting || queryOptionLoading) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            BASE_PATH={BASE_PATH}
            messageApi={messageApi}
            translate={translate}
            merchantId={merchantPortalOptionSetting.merchantId}
            deviceType={deviceType}
            portalStyleSettings={merchantPortalStyleSettings}
            portalOptionSettingViewModel={merchantPortalOptionSetting}
            config={merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'SearchPage')[0]?.styleSetting || {}}
            items={itemVMs}
            queryState={memoizedQueryState}
        />
    );
};

export default SearchPage;