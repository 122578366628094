import React from 'react';

export const HtmlPage: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const content = decodeURIComponent(params.get('data') || '');

    return (
        <div className="container mx-auto p-4">
            <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
}; 