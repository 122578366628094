import { CheckOutProvider } from "@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import React from 'react';

export const withContexts = (WrappedComponent: React.ComponentType<any>) => {
    return (props: any) => (
        <GlobalContext.Consumer>
            {({ deviceType, merchantId, isLogin, tryGetSessionId, messageApi }) => (
                <TranslationContext.Consumer>
                    {({ translate, getCountrySelectionDisplayName }) => (
                        <CheckOutProvider
                            translate={translate}
                            merchantId={merchantId}
                            isLogin={isLogin}
                            tryGetSessionId={tryGetSessionId}
                            messageApi={messageApi}
                        >
                            <WrappedComponent
                                {...props}
                                merchantId={merchantId}
                                deviceType={deviceType}
                                translate={translate}
                                getCountrySelectionDisplayName={getCountrySelectionDisplayName}
                            />
                        </CheckOutProvider>
                    )}
                </TranslationContext.Consumer>
            )}
        </GlobalContext.Consumer>
    );
};