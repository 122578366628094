import { BASE_PATH } from "@/constants/routes";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import queryString from 'query-string';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FacebookCallbackPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Facebook 回傳的 access_token 在 URL hash 中
        const hash = window.location.hash.substring(1);
        const { access_token, state } = queryString.parse(hash);

        if (access_token) {
            try {
                const origin = state ? decodeURI(state as string) : '';
                window.location.replace(`${decodeURI(origin)}/proxy-login?method=facebook&token=${access_token}`);
            } catch (error) {
                console.error('Facebook 登入錯誤:', error);
            }
        } else {
            console.log('無法取得 access_token');
            navigate(`${BASE_PATH}/error`);
        }
    }, []);

    return (
        <LoadingComponent></LoadingComponent>
    );
}

export default FacebookCallbackPage;